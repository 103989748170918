import { useNavigate } from 'react-router-dom';
import { track } from '@amplitude/analytics-browser';

import AccountOverview, { OverviewOption } from './AccountOverview';
import { CreditLineAccountV2Response } from '../../../types/generated/pos';
import { parseDate } from '@utils/date';
import { formatMoney } from '@utils/formatMoney/formatMoney';
import { useGetCreditAgreementApi } from '@api/services/accountService/useGetAgreementApi';
import { AmplitudeEvents } from '@utils/amplitudeEvents';
import { getCreditEventOptions } from '../utils/eventOptions';
import { getDateWithYear } from '@utils/dates/formatDates';
import { Alert, Snackbar } from '@fhl-world/salmon-ui';
import styled from '@emotion/styled';
import { useMemo, useState } from 'react';

type CreditOverviewProps = {
	credit: CreditLineAccountV2Response;
};

const ErrorNotification = styled(Alert)`
	border-radius: 12px;
`;

const CreditOverview = (props: CreditOverviewProps) => {
	const { credit } = props;
	const [isAlertShown, setShowAlert] = useState(false);
	const [isAgreementButtonDisabled, setAgreementButtonDisabled] =
		useState(false);
	const agreementState = useGetCreditAgreementApi();

	const parsedDueDate = credit.dueDate && parseDate(credit.dueDate);
	const formattedDueDate = parsedDueDate && getDateWithYear(parsedDueDate);

	const navigate = useNavigate();

	const errorAgreementMessage =
		typeof agreementState.error === 'string'
			? agreementState.error
			: agreementState?.error?.message || 'Something went wrong';

	const transactions = {
		label: 'Transactions',
		onClick: () => {
			// TODO различаются события аналитики для транзакций кредита и кэшлоана
			track(AmplitudeEvents.TransactionHistory_Tap, {
				type: 'CreditLine',
			});
			navigate(`/history/${credit.id}`);
		},
	};

	const outstanding = {
		label: 'Outstanding balance',
		text: formatMoney(credit.balance.outstanding),
	};

	const dueDate = formattedDueDate
		? { label: 'Due date', text: formattedDueDate }
		: null;

	const limit = {
		label: 'Credit limit',
		text: formatMoney(credit.balance.available),
	};

	const agreement = {
		label: 'Salmon Credit Agreement',
		isDisabled: isAgreementButtonDisabled || agreementState.loading,
		onClick: () => {
			if (!agreementState.data?.location) {
				setShowAlert(true);
				setAgreementButtonDisabled(true);
				return;
			}
			track(AmplitudeEvents.Amount_Tap, {
				product: getCreditEventOptions(credit),
				source: 'CreditlineMain',
			});
			window.open(agreementState.data.location, '_blank');
		},
	};

	const options = useMemo(() => {
		return [transactions, outstanding, dueDate, limit, agreement].filter(
			Boolean
		) as OverviewOption[];
	}, [agreement, isAgreementButtonDisabled]);

	return (
		<>
			<Snackbar
				open={isAlertShown}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				onClose={() => setShowAlert(false)}
				autoHideDuration={5000}
			>
				<ErrorNotification severity="error" onClose={() => setShowAlert(false)}>
					Oops! {errorAgreementMessage} <br />
					Please try again later.
				</ErrorNotification>
			</Snackbar>
			<AccountOverview options={options} />
		</>
	);
};

export default CreditOverview;
